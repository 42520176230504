import React, { useState } from 'react';
import './Footer.css';

function Footer() {
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [formStatus, setFormStatus] = useState('');

  const sendEmail = async (e) => {
    e.preventDefault();

    const formData = {
      _replyto: userEmail,
      user_name: userName,
      user_email: userEmail,
      user_phone: userPhone,
    };

    try {
      const response = await fetch('https://formspree.io/f/xkggpaon', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setFormStatus('Email sent successfully!');
        alert('Cadastro bem sucedido');
        setUserName('');
        setUserEmail('');
        setUserPhone('');
      } else {
        setFormStatus('Error sending email.');
      }
    } catch (error) {
      setFormStatus('Error sending email.');
      console.error(error);
    }
  };

  return (
    <div className="footer-container">
      <div className="footer-content">
        <div className="footer-subscription">
          <p className="footer-subscription-heading">
            Cadastre-se e <br /> receba nossos conteúdos
          </p>
        </div>

        <form onSubmit={sendEmail} className="footer-input-section">
          <div className="footer-input-row">
            <input
              className="footer-input"
              name="user_name"
              type="text"
              placeholder="Seu nome"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              required
            />
            <input
              className="footer-input"
              name="user_email"
              type="email"
              placeholder="Seu melhor e-mail"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              required
            />
          </div>

          <div className="footer-input-row">
            <input
              className="footer-input"
              name="user_phone"
              type="text"
              placeholder="Seu número"
              value={userPhone}
              onChange={(e) => setUserPhone(e.target.value)}
              required
            />
            <button className="footer-button" type="submit">
              Cadastrar
            </button>
          </div>
        </form>
      </div>

      <div className="footer-social-icons">
        <a href="https://www.instagram.com/matheusboasorte/" className="footer-social-icon">
          <i className="fab fa-instagram"></i>
        </a>
        <a href="https://www.facebook.com/matheusboasorte/?locale=pt_BR" className="footer-social-icon">
          <i className="fab fa-facebook"></i>
        </a>
        <a href="https://www.youtube.com/matheusboasorte" className="footer-social-icon">
          <i className="fab fa-youtube"></i>
        </a>
      </div>

      <p className="copy-text">© 2025 Matheus Boa Sorte</p>
    </div>
  );
}

export default Footer;