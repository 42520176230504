import React from "react";
import "./VideoSec.css";

const VideoSec = () => {
  const videoUrl = "https://www.youtube.com/embed/3OD5hOGmxcE?si=M_vS0qfctkTDv9Y6";

  return (
    <div className="video-story-wrapper">
      <div className="video-story-content">
        <div className="video-arrow">
          <div className="arrow-container">
            <div className="play-icon">&#9654;</div>
          </div>
          <span className="arrow-text">Aperte o play</span>
        </div>

        <div className="video-strip">
          <div className="video-container">
            <iframe
              className="video-player"
              src={videoUrl}
              title="Author's Story"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoSec;
