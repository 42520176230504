import React, { useState } from 'react';
import './ContactForm.css';

function ContactForm() {
  const [user_nome, setUserNome] = useState('');
  const [user_email, setUserEmail] = useState('');
  const [user_phone, setUserPhone] = useState('');
  const [user_assunto, setUserAssunto] = useState('');
  const [user_mensagem, setUserMensagem] = useState('');
  const [formStatus, setFormStatus] = useState('');

  const sendEmail = async (e) => {
    e.preventDefault();

    const formData = {
      _replyto: user_email,
      user_nome: user_nome,
      user_email: user_email,
      user_phone: user_phone,
      user_assunto: user_assunto,
      user_mensagem: user_mensagem,
    };

    try {
      const response = await fetch('https://formspree.io/f/xpwwoeqa', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (response.ok) {
        setFormStatus('Email sent successfully!');
        alert('Mensagem enviada com sucesso');
        setUserNome('');
        setUserEmail('');
        setUserPhone('');
        setUserAssunto('');
        setUserMensagem('');
      } else {
        setFormStatus(`Error: ${data.error || 'Error sending email.'}`);
        console.error('Form submission error:', data);
      }
    } catch (error) {
      setFormStatus('Error sending email.');
      console.error('Network error:', error);
    }
  };

  return (
    <div className="contact-form-container">
      <div className="contact-form-content">
        <h2 className="contact-form-heading">Contate-nos</h2>

        <form className="contact-form" onSubmit={sendEmail}>
          <div className="contact-form-row">
            <input
              className="contact-form-input"
              name="user_nome"
              type="text"
              placeholder="Seu nome"
              value={user_nome}
              onChange={(e) => setUserNome(e.target.value)}
              required
            />
            <input
              className="contact-form-input"
              name="user_email"
              type="email"
              placeholder="Seu e-mail"
              value={user_email}
              onChange={(e) => setUserEmail(e.target.value)}
              required
            />
          </div>

          <div className="contact-form-row">
            <input
              className="contact-form-input"
              name="user_phone"
              type="text"
              placeholder="Seu número"
              value={user_phone}
              onChange={(e) => setUserPhone(e.target.value)}
              required
            />
            <input
              className="contact-form-input"
              name="user_assunto"
              type="text"
              placeholder="Assunto"
              value={user_assunto}
              onChange={(e) => setUserAssunto(e.target.value)}
              required
            />
          </div>

          <textarea
            className="contact-form-textarea"
            name="user_mensagem"
            placeholder="Sua mensagem"
            value={user_mensagem}
            onChange={(e) => setUserMensagem(e.target.value)}
            required
          ></textarea>

          <button className="contact-form-button" type="submit">
            Enviar
          </button>
        </form>
      </div>
    </div>
  );
}

export default ContactForm;