import React, { useState, useEffect } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { getMessages, getPhotos } from './services/apiService';

import Nav from '../components/Nav';
import Hero from '../components/Hero';
import Footer from '../components/Footer';
import './BlogPostPage.css';

function BlogPostPage() {
  const { newsID } = useParams();
  const [post, setPost] = useState(null);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  useEffect(() => {
    const fetchPostData = async () => {
      setLoading(true);

      try {
        const messagesData = await getMessages();
        const selectedPost = messagesData.find((message) => message.id === parseInt(newsID));

        if (selectedPost) {
          setPost(selectedPost);
          await fetchRelatedImages(selectedPost.id);
        } else {
          setPost(null);
        }
      } catch (error) {
        setPost(null);
      } finally {
        setLoading(false);
      }
    };

    fetchPostData();
  }, [newsID]);

  const fetchRelatedImages = async (postId) => {
    try {
      const allPhotos = await getPhotos();
      const postImages = allPhotos.filter((photo) => photo.newsId === postId);

      const imagesBase64 = postImages.map((photo) => `data:${photo.fileContent};base64,${photo.fileBase64}`);
      setImages(imagesBase64);
    } catch (error) {
      console.error('Error fetching related images:', error);
    }
  };

  const handleImageClick = (imageSrc) => {
    setCurrentImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentImage(null);
  };

  const stripHTML = (content) => {
    return content.replace(/<[^>]*>/g, '');
  };

  if (loading) {
    return (
      <div className="loading-spinner-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  if (!post) {
    return <Navigate to="/not-found" />;
  }

  return (
    <div className="blog-post-page">
      <Nav />
      <Hero />

      <div className="post-container">
        <h1 className="post-title">{post.title}</h1>

        <div className="post-content">
          <p>{stripHTML(post.content)}</p>
        </div>

        <div className="post-images">
          {images.length > 0 && (
            <div className="image-container">
              <img
                src={images[0]}
                alt="Related image 1"
                className="post-image post-image-first"
                onClick={() => handleImageClick(images[0])}
              />
            </div>
          )}

          {images.length > 1 && (
            <div className="image-container">
              <img
                src={images[1]}
                alt="Related image 2"
                className="post-image"
                onClick={() => handleImageClick(images[1])}
              />
            </div>
          )}

          {images.length > 2 && (
            <div className="image-container">
              <img
                src={images[2]}
                alt="Related image 3"
                className="post-image"
                onClick={() => handleImageClick(images[2])}
              />
            </div>
          )}
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={currentImage} alt="Opened photo" className="modal-image" />
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
}

export default BlogPostPage;