import React from "react";
import "./PhotoSec.css";

import photo1 from '../resources/imgMatheusHorizontal.JPG';
import photo2 from '../resources/imgMatheusVertical.JPG';
import photo3 from '../resources/imgEspetaculo.webp';
import photo4 from '../resources/imgJaca.jpeg';
import photo5 from '../resources/imgMatheusSertao.JPG';
import photo6 from '../resources/imgDubai.jpeg';
import photo7 from '../resources/imgCondeuba.jpeg';
import photo8 from '../resources/imgMatheus.jpeg';

const PhotoSec = () => {
  const photos = [
    { src: photo1, description: "Descriçao foto número 1" },
    { src: photo2, description: "Descriçao foto número 2" },
    { src: photo3, description: "Descriçao foto número 3" },
    { src: photo4, description: "Descriçao foto número 4" },
    { src: photo5, description: "Descriçao foto número 5" },
    { src: photo6, description: "Descriçao foto número 6" },
    { src: photo7, description: "Descriçao foto número 7" },
    { src: photo8, description: "Descriçao foto número 8" },
  ];

  return (
    <div className="gallery-wrapper">
      <div className="gallery-container">
        {photos.map((photo, index) => (
          <div key={index} className="gallery-item">
            <img src={photo.src} alt={`Owner ${index + 1}`} className="gallery-img" />
            <div className="overlay">
              <p className="description">{photo.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PhotoSec;
