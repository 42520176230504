import React from 'react';
import './AboutBSV.css';
import imgMatheus1 from '../resources/imgBSV.jpg';
import imgMatheus2 from '../resources/imgBSV2.JPG';

const AboutBSV = () => {
  return (
    <section className="about-bsv-section">
      <div className="about-brief-content">
        <div className="about-bsv-images-bsv">
          <div className="about-brief-left">
            <img src={imgMatheus1} alt="Author" className="about-brief-image" />
          </div>
          <div className="about-brief-right">
            <img src={imgMatheus2} alt="Author" className="about-brief-image" />
          </div>
        </div>
        <div className="about-brief-text">
          <h2>Conheça o Boa Sorte Viajante</h2>
          <p>
            Nós somos apaixonados por histórias. E é no Boa Sorte Viajante que você vai viajar sem nem levantar do sofá da sua sala. Com o apresentador Matheus Boa Sorte vamos desbravando o Brasil para encontrar grandes personagens e vivenciar experiências inesquecíveis.          </p>
          <p>
            Nosso programa reúne belas paisagens, sabores marcantes e o Brasil retratado através da diversidade cultural que você só encontra nesse país. Semanalmente no canal do YouTube você encontra um episódio desenhado para que você, telespectador, possa ver pelos nossos olhos o encanto observado em cada viagem.
          </p>
          <a
            href="https://boasorteviajante.com.br/"
            className="read-more-btn-bsv"
            target="_blank"
            rel="noopener noreferrer"
          >
            Acesse o portal do Boa Sorte Viajante
          </a>
        </div>
      </div>
    </section>
  );
};

export default AboutBSV;