import React, { useState, useEffect } from 'react';
import { getMessages, getPhotos } from './services/apiService';
import { useNavigate } from 'react-router-dom';
import './BlogHighlights.css';

function BlogHighlights() {
    const [messages, setMessages] = useState([]);
    const [images, setImages] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        fetchMessages();
    }, []);

    const fetchMessages = async () => {
        try {
            const data = await getMessages();
            const messagesWithDate = data.map((message) => ({
                ...message,
                createdAt: message.createdAt || new Date().toISOString(),
            }));
            setMessages(messagesWithDate.slice(0, 3));
            fetchImagesForMessages(messagesWithDate.slice(0, 3));
        } catch (error) {
            console.error('Failed to fetch messages:', error);
        }
    };

    const fetchImagesForMessages = async (messages) => {
        try {
            const allPhotos = await getPhotos();
            const imagesMap = {};

            allPhotos.forEach((photo) => {
                if (photo.newsId) {
                    if (!imagesMap[photo.newsId]) {
                        imagesMap[photo.newsId] = [];
                    }

                    const imageSrc = photo.fileBase64 ? `data:${photo.fileContent};base64,${photo.fileBase64}` : null;
                    if (imageSrc) {
                        imagesMap[photo.newsId].push(imageSrc);
                    }
                }
            });

            setImages(imagesMap);
        } catch (error) {
            console.error('Failed to fetch images:', error);
        }
    };

    const getPreviewContent = (content) => {
        const plainText = content.replace(/<[^>]*>/g, '');
        return plainText.length > 150 ? plainText.slice(0, 150) + '...' : plainText;
    };

    const handlePostClick = (postId) => {
        navigate(`/blog/post/${postId}`);
    };

    return (
        <div
            className="blog-highlights-wrapper"
            style={{ backgroundColor: '#f4f4f4' }}
        >
            <div className="blog-highlights-container">
                <h1 className="blog-highlights-title">Destaques do Blog</h1>
                <div className="blog-highlights-grid">
                    {messages.map((message) => (
                        <div key={message.id} className="blog-highlight-card">
                            <div className="blog-highlight-image">
                                {images[message.id] && images[message.id][0] ? (
                                    <img
                                        src={images[message.id][0]}
                                        alt={`Highlight image for ${message.title}`}
                                        className="highlight-image"
                                    />
                                ) : (
                                    <div className="no-image">Carregando imagem...</div>
                                )}
                            </div>
                            <div className="blog-highlight-content">
                                <h2 className="highlight-title">{message.title}</h2>
                                <p className="highlight-excerpt">{getPreviewContent(message.content)}</p>
                                {/* Mudando o link para o redirecionamento dinâmico */}
                                <a
                                    href=""
                                    onClick={() => handlePostClick(message.id)} // Usando o handlePostClick para navegar
                                    className="read-more-button"
                                >
                                    Ver mais...
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default BlogHighlights;
