import React, { useState, useEffect } from 'react';
import { getMessages, getPhotos } from './services/apiService'; 
import { useNavigate } from 'react-router-dom';
import './BlogComponent.css';

function BlogComponent() {
  const [messages, setMessages] = useState([]);
  const [images, setImages] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPostId, setLoadingPostId] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchMessages();
  }, []);

  useEffect(() => {
    filterMessages();
  }, [searchQuery, messages]);

  const fetchMessages = async () => {
    try {
      const data = await getMessages();
      const messagesWithDate = data.map((message) => ({
        ...message,
        createdAt: message.createdAt || new Date().toISOString(),
      }));
      setMessages(messagesWithDate);
      fetchImagesForMessages(messagesWithDate);
    } catch (error) {
      console.error('Failed to fetch messages:', error);
    }
  };

  const fetchImagesForMessages = async (messages) => {
    try {
      const allPhotos = await getPhotos();
      const imagesMap = {};

      allPhotos.forEach((photo) => {
        if (photo.newsId) {
          if (!imagesMap[photo.newsId]) {
            imagesMap[photo.newsId] = [];
          }

          const imageSrc = photo.fileBase64 ? `data:${photo.fileContent};base64,${photo.fileBase64}` : null;
          if (imageSrc) {
            imagesMap[photo.newsId].push(imageSrc);
          }
        }
      });

      setImages(imagesMap);
    } catch (error) {
      console.error('Failed to fetch images:', error);
    }
  };

  const filterMessages = () => {
    const filtered = messages.filter((message) =>
      message.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredMessages(filtered);
  };

  const handlePostClick = (postId) => {
    setLoading(true);
    setLoadingPostId(postId);

    setTimeout(() => {
      navigate(`/blog/post/${postId}`);
      setLoading(false);
    }, 1500);
  };

  const getPreviewContent = (content) => {
    const plainText = content.replace(/<[^>]*>/g, '');
    return plainText.length > 150 ? plainText.slice(0, 150) + '...' : plainText;
  };

  return (
    <div className="blog-container">
      <h1 className="blog-title">Posts mais recentes</h1>

      <div className="search-container">
        <input
          type="text"
          placeholder="Pesquisar por título..."
          className="search-input"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      <div className="blog-list">
        {filteredMessages.length > 0 ? (
          filteredMessages.map((message) => (
            <div
              className="blog-card"
              key={message.id}
              onClick={() => handlePostClick(message.id)}
            >
              <div className="blog-card-image">
                {images[message.id] && images[message.id][0] ? (
                  <img
                    src={images[message.id][0]}
                    alt={`Post photo for ${message.title}`}
                  />
                ) : (
                  <div className="loading-placeholder">Carregando imagem...</div>
                )}
              </div>
              <div className="blog-card-content">
                <h2 className="blog-card-title">{message.title}</h2>
                <p className="blog-card-excerpt">{getPreviewContent(message.content)}</p>
              </div>
            </div>
          ))
        ) : (
          <p className="no-results">Nenhum post encontrado...</p>
        )}
      </div>

      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}
    </div>
  );
}

export default BlogComponent;
