import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../resources/imgLogo.png';
import './Nav.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

function NavBar() {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const scrollToPosition = (percentage) => {
        console.log(window.innerHeight);
        window.scrollTo({
            top: window.innerHeight * percentage,
            behavior: 'smooth'
        });
        closeMobileMenu();
    };

    useEffect(() => {
        const showButton = () => {
            if (window.innerWidth <= 960) {
                setClick(false);
            }
        };

        window.addEventListener('resize', showButton);
        return () => window.removeEventListener('resize', showButton);
    }, []);

    return (
        <nav className='navbar'>
            <div className='navbar-container'>

                <Link
                    to='/'
                    className='navbar-logo'
                    onClick={() => closeMobileMenu()}
                >
                    <img src={logo} alt="Logo" className='navbar-logo-image' />
                </Link>
                <div className='menu-icon' onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <Link
                            to='/'
                            className='nav-links'
                            onClick={() => scrollToPosition(-window.innerHeight)}
                        >
                            Início
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link
                            to='/biografia'
                            className='nav-links'
                            onClick={() => scrollToPosition(-window.innerHeight)}
                        >
                            Biografia
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link
                            to='/palestras'
                            className='nav-links'
                            onClick={() => scrollToPosition(-window.innerHeight)}
                        >
                            Palestras
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link
                            to='/produtos'
                            className='nav-links'
                            onClick={() => scrollToPosition(-window.innerHeight)}
                        >
                            Produtos
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link
                            to='/blog'
                            className='nav-links'
                            onClick={() => scrollToPosition(-window.innerHeight)}
                        >
                            Blog
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link
                            to='/contato'
                            className='nav-links'
                        >
                            Contato
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default NavBar;