import React, { useEffect, useState } from 'react';
import { getProducts, getPhotos } from './services/apiService';
import './ProductHighlights.css';

const ProductHighlights = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchImagesAndProducts = async () => {
      try {
        setLoading(true); 

        const productData = await getProducts();
        console.log("Fetched products:", productData);

        const imageData = await getPhotos();
        console.log("Fetched images:", imageData);

        const productImages = productData.map(product => {
          const image = imageData.find(image => image.productId === product.id);

          return {
            ...product,
            image: image ? `data:${image.fileContent};base64,${image.fileBase64}` : null
          };
        });

        console.log("Products with images:", productImages);

        setProducts(productImages);
      } catch (error) {
        console.error("Error fetching product highlights:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchImagesAndProducts();
  }, []);

  return (
    <div className="product-highlights-wrapper">
      <div className="product-highlights-container">
        <h1 className="prod-highlight-title">Nossos produtos</h1>

        <div className="product-grid">
          {loading ? (
            <div className="loading-products-highlights">
              <div className="ball-highlights"></div>
            </div>
          ) : (
            products.length > 0 ? (
              products.slice(0, 3).map((product, index) => {
                if (!product.image) {
                  console.error("Image data is missing for product:", product);
                  return null;
                }

                const productName = product.name || "Produto sem título";

                return (
                  <div
                    key={product.id}
                    className={`product-card ${index === 0 ? 'large' : 'small'}`}
                  >
                    <a href={`/produtos`} className="product-card-link">
                      <img
                        src={product.image}
                        alt={`Product ${product.id}`}
                        className="product-card-image"
                      />
                      <span className="product-card-overlay">Ver mais...</span>
                    </a>

                    <h3 className="product-title">{productName}</h3>

                    <div
                      className="product-description"
                      dangerouslySetInnerHTML={{ __html: product.description }}
                    />
                  </div>
                );
              })
            ) : (
              <div className='no-prods'></div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductHighlights;