import React, { useEffect, useState } from 'react';
import './BioSec.css';

function BioSec() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`bio-container ${isVisible ? 'visible' : ''}`}>
      <div className="bio-content">

        <p>
          Matheus Boa Sorte é jornalista, publicitário, youtuber, educador, escritor, cantor, compositor. Nascido em Guanambi, em 1993, começou a trabalhar com comunicação ainda adolescente, quando tinha um programa em uma rádio local.
        </p>
        <p>
          Especialista em Storytelling, durante 7 anos fez parte do elenco da TV Aratu, onde comandou o Dendê na Mochila e também foi repórter ebioial para todo o Brasil através do SBT Notícias. Como escritor, lançou em 2017 o livro "Matuto Poeta - Histórias Rimadas no Sertão". Na música, onde anualmente faz uma turnê junina que viaja à Bahia, dedica-se desde 2018 ao verdadeiro e autêntico forró, ganhando destaque com canções autorais como "Nordestino Sim Senhor" e "Vai Sonhando Que Dá Certo".
        </p>
        <p>
          Sua forte presença nas redes sociais impacta diretamente mais de 400 mil pessoas, que se conectam às boas histórias que Boa Sorte por lá compartilha. Atualmente também se dedica a palestras e treinamentos onde defende que a boa comunicação fica muito mais persuasiva com o uso do Storytelling.
        </p>
      </div>
      <div className="bio-background-image"></div>
    </div>
  );
}

export default BioSec;
